import { getApi } from "@/service";
import { useQuery } from "react-query";

export const useQueryGetSellersWithNoSales = (
    companyId: number,
    days: number | undefined,
    options: object
  ) =>
    useQuery(
      ["queryGetSellersWithNoSales", companyId],
      async () => {
        const api = await getApi();
  
        return api
          .post("/mobile/report/query_v2", {
            companyId,
            days,
            report: "vendedor_sem_venda",
          })
          .then((result) => result.data.dados);
      },
      options
    );
  