import { useCallback, useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";

import { Button, PageLoader, useToast } from "@/components";
import { DataTable } from "@/components/DataTable/DataTable";
import {
  useMutationSetUserState,
  useQueryGetParameterizationValues,
} from "@/service/administrativeArea/administrative-area";
import { useCompanyStore } from "@/store/company";

import { useQueryGetSellersWithNoSales } from "@/service/reports/sales/notifications";
import { AdministrativeAreaUsersBySalesNotificationsParams } from "./AdministrativeAreaUsersNotificationsParams";
import { useQueryClient } from "react-query";

export const AdministrativeAreaUsersBySales = () => {
  const { toast } = useToast();
  const companySelected = useCompanyStore((store) => store.company);

  const { data: parameterizationValues } = useQueryGetParameterizationValues(
    companySelected.id,
    {}
  );

  const { data: dataSales, isLoading } = useQueryGetSellersWithNoSales(
    companySelected.id,
    parameterizationValues?.notifications_days_fired_users,
    {}
  );

  const queryClient = useQueryClient();

  const { mutate: mutateUserState } = useMutationSetUserState({
    onSuccess: () => {
      toast({
        variant: "destructive",
        title: `Usuário desativado com sucesso!`,
      });

      queryClient.invalidateQueries(["queryGetSellersWithNoSales"]);
     
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: "Erro ao desativar usuário",
      });
    },
  });
  
  const handleChangeUserState = useCallback(
    (userId: number) => {
      mutateUserState(userId);
      
    },
    [mutateUserState]
  );

  const columns: ColumnDef<any>[] = useMemo(
    () => [
      {
        accessorKey: "full_name",
        accessorFn: (row) => `${row.first_name} ${row.last_name}`,
        header: "Nome",
      },
      {
        accessorKey: "days_without_sale",
        header: "Dias sem vendas",
      },
      {
        header: "Ação",
        cell: ({ row }) => {
          return (
            <Button
              variant="outline"
              onClick={() => handleChangeUserState(row.original.user_id)}
            >
              Desativar
            </Button>
          );
        },
      },
    ],
    [handleChangeUserState]
  );

  if (isLoading) return <PageLoader />;
  return (
    <>

      <AdministrativeAreaUsersBySalesNotificationsParams/>
      <DataTable
        data={dataSales}
        columns={columns}
        autoResetPageIndex={false}
        isHeaderSticky
        hasFilter = {true}
        height="h-[56vh] max-sm:h-[48vh]"
      />
    </>
  );
};
