import { Card, CardContent, CardHeader, Tabs, TabsContent, TabsList, TabsTrigger } from "@/components";
import { AdministrativeAreaUsersGeneral } from "./AdministrativeAreaUsersGeneral";
import { HeaderAdministrative } from "../components/HeaderAdministrative";
import { AdministrativeAreaUsersBySales } from "./AdministrativeAreaUsersBySales";
import { useSearchParams } from "react-router-dom";

export const AdministrativeAreaUsers = () =>{

  const [searchParams] = useSearchParams();
  const selectedTabs = searchParams.get("selectedTabs") || "parameters";
  
    return (
        <Card className="h-full max-md:h-[calc(100vh-130px)] overflow-auto">
          <CardHeader className="max-md:px-3">
            <HeaderAdministrative
              title="Usuários"
              subtitle="Informações dos usuários da empresa."
            />
          </CardHeader>
    
          <CardContent className="max-md:px-3 max-md:pb-2">
            <Tabs defaultValue={selectedTabs || "parameters"}>
              <TabsList className="w-full flex max-md:grid max-md:grid-cols-2 max-md:gap-y-8 max-md:pb-12 max-md:pt-4 max-md:grid-rows-2">
                <TabsTrigger value="parameters" className="w-full">
                  Usuários Gerais
                </TabsTrigger>
    
                <TabsTrigger value="noSales" className="w-full">
                  Usuários Por Dias Sem Venda
                </TabsTrigger>
              </TabsList>
    
              <TabsContent value="parameters" className="md:px-6 overflow-auto">
                <AdministrativeAreaUsersGeneral />
              </TabsContent>
    
              <TabsContent value="noSales" className="md:px-6 overflow-auto">
                <AdministrativeAreaUsersBySales />
              </TabsContent>
            </Tabs>
          </CardContent>
        </Card>
      );

} 