import { useQueryClient } from "react-query";
import {
  CardContent,
  CardDescription,
  Input,
  Label,
  Skeleton,
  useToast,
} from "@/components";
import { useQueryGetParameterizationValues } from "@/service/administrativeArea/administrative-area";
import { useCompanyStore } from "@/store/company";
import { useMutationParameterizationNotifications } from "@/service/administrative-area";
import { useState, useEffect } from "react";

export const AdministrativeAreaUsersBySalesNotificationsParams = () => {
  const [data, setData] = useState<string>("0");
  const companySelected = useCompanyStore((store) => store.company);
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { data: fetchedDataNotifications, isLoading } =
    useQueryGetParameterizationValues(companySelected.id, {});

  const [dataNotifications, setDataNotifications] = useState<{ notifications_days_fired_users: number }>({
    notifications_days_fired_users: fetchedDataNotifications?.notifications_days_fired_users,
  });


  useEffect(() => {
    if (fetchedDataNotifications) {
      setDataNotifications({
        notifications_days_fired_users: fetchedDataNotifications.notifications_days_fired_users,
      });
    }
  }, [fetchedDataNotifications]);

  const { mutate } = useMutationParameterizationNotifications({
    onSuccess: () => {
      toast({
        variant: "default",
        title: "Dias relevantes de notificação alterados com sucesso!",
      });
      queryClient.invalidateQueries({
        queryKey: ["queryGetParameterizationValues"],
      });
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        title: "Erro ao alterar os dias relevantes de notificação",
        description: error?.response?.data?.error || "Erro desconhecido",
      });
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setData(value.replace(/[^0-9]/g, ""));
    
   
    setDataNotifications((prev) => ({
      ...prev,
      notifications_days_fired_users: parseInt(value) || 0,
    }));
  };

  const handleBlur = () => {
    mutate({
      companyId: companySelected.id,
      notifications_days_fired_users: Number(data),
    });
  };

  return (
    <CardContent className="max-md:px-3 max-md:pb-2">
      <div className="flex flex-col pt-2">
        {isLoading && (
          <div className="flex flex-col space-y-4">
            <Skeleton className="w-72 h-4" />
            <Skeleton className="w-full h-9" />
            <Skeleton className="w-32 h-8 self-end" />
          </div>
        )}
        {fetchedDataNotifications && (
          <>
            <Label className="pb-3">Dias considerados</Label>
            <Input
              type="string"
              min={0}
              max={100}
              value={dataNotifications.notifications_days_fired_users}
              placeholder="Dias considerados para a notificação"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <CardDescription className="pt-3">
              Número de dias sem vendas que determina se um vendedor/usuário é
              considerado potencialmente desligado da empresa.
            </CardDescription>
          </>
        )}
      </div>
    </CardContent>
  );
};
