import { Skeleton } from "../ui";

export const SheetFormFilterSkeleton = ({
  inputQuantity = 5,
}: {
  inputQuantity?: number;
}) => {
  return (
    <div className="flex flex-col">
      <Skeleton className="w-72 h-8" />
      <div className="flex flex-col gap-y-5 justify-start pt-8">
        {new Array(inputQuantity).fill(null).map((_, i) => (
          <div key={i} className="flex flex-col gap-y-3">
            <Skeleton className="w-32 h-4" />
            <Skeleton className="h-11 w-full" />
          </div>
        ))}
      </div>
    </div>
  );
};
