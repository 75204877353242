import * as React from "react";
import * as ReactDOM from "react-dom/client";

import "./index.css";
import App from "./App";
import { Providers } from "./providers";

import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-quartz.css";

import * as Sentry from "@sentry/react";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorComponent } from "./components";

Sentry.init({
  dsn: "https://d6a0df067684ed0ea714c99b4712c278@o4508561370578944.ingest.us.sentry.io/4508561372938240",
  integrations: [],
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={({ resetErrorBoundary }) => (
        <ErrorComponent handleReload={resetErrorBoundary} showSuportMessage />
      )}
    >
      <Providers>
        <App />
      </Providers>
    </ErrorBoundary>
  </React.StrictMode>
);
