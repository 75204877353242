import { DropdownMenuItem, Separator } from "@/components";

import { AdministrativeAreaActionsProps } from "@/types/administrativeArea";
import { AdministrativeAreaUsersActionsEdit } from "./AdministrativeAreaUsersActionsEdit";
import { AdministrativeAreaUsersActionsLinkSeller } from "./AdministrativeAreaUsersActionsLinkSeller";
import { AdministrativeAreaUsersActionsUserState } from "./AdministrativeAreaUsersActionsUserState";

export const AdministrativeAreaUsersActions: React.FC<
  AdministrativeAreaActionsProps
> = ({ row }) => {
  return (
    <>
      <AdministrativeAreaUsersActionsUserState row={row} />

      <Separator />

      <AdministrativeAreaUsersActionsLinkSeller row={row} />
      <AdministrativeAreaUsersActionsEdit row={row}>
        <DropdownMenuItem
          onSelect={(event) => {
            event.preventDefault();
          }}
        >
          Editar usuário
        </DropdownMenuItem>
      </AdministrativeAreaUsersActionsEdit>
    </>
  );
};
