import React from "react";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { Row } from "@tanstack/react-table";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components";
import { Button } from "..";
import {
  useRowStore,
  useSubRowStore,
} from "../../routes/AdministrativeArea/store";
import { cn } from "@/lib/utils";

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  rowAction: React.ReactElement;
  subRowStore?: boolean;
  isSubComponent?: boolean;
}

export function DataTableRowActions<TData>({
  row,
  rowAction,
  isSubComponent,
  subRowStore = false,
}: DataTableRowActionsProps<TData>) {
  const storeRow = useRowStore((state) => state.setRow);
  const storeSubRow = useSubRowStore((state) => state.setRow);

  const handleStoreRow = () => {
    storeRow(row);
  };

  const handleStoreSubRow = () => {
    storeSubRow(row);
  };

  return (
    <DropdownMenu
      onOpenChange={subRowStore ? handleStoreSubRow : handleStoreRow}
    >
      <DropdownMenuTrigger>
        <Button
          variant="ghost"
          className={cn(
            "data-[state=open]:bg-muted h-12",
            isSubComponent && "h-9"
          )}
        >
          <DotsHorizontalIcon className="h-4 w-4" />
          <span className="sr-only">Abrir menu</span>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="flex flex-col">
        {React.cloneElement(rowAction, { row })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
