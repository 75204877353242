import { useQueryClient } from "react-query";

import { DropdownMenuItem, useToast } from "@/components";
import { useMutationSetUserState } from "@/service/administrativeArea/administrative-area";

export const AdministrativeAreaUsersActionsUserState = ({ row }) => {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const { mutate: mutateUserState } = useMutationSetUserState({
    onSuccess: () => {
      queryClient.refetchQueries(["queryGetUsers"]);

      if (row.original.is_active) {
        toast({ variant: "destructive", title: "Usuário desativado!" });
        return;
      }

      toast({ variant: "default", title: "Usuário ativado!" });
    },
  });

  const handleChangeUserState = () => {
    mutateUserState(row.original.id);
    queryClient.invalidateQueries(["queryGetSellersWithNoSales"]);
  };

  return (
    <div className="grid align-middle justify-center">
      <DropdownMenuItem className="h-10 w-48" onSelect={handleChangeUserState}>
        {row.original.is_active ? "Desativar usuário" : "Ativar usuário"}
      </DropdownMenuItem>
    </div>
  );
};
