import { UserGroups } from "@/constants/user";
import { useCompanyStore } from "@/store/company";
import { useUserStore } from "@/store/user";
import { HelpCircle, LayoutGrid, Logs, MapPinCheck } from "lucide-react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "../ui";

const complementaryApps = [
  {
    name: "Logs",
    icon: Logs,
    link: "/logs",
    userGroupsAllowed: [
      UserGroups.admin,
      UserGroups.supervisor,
      UserGroups.generalSupervisor,
    ],
  },
  {
    name: "Central de Ajuda",
    icon: HelpCircle,
    link: "/help-center",
    userGroupsAllowed: [
      UserGroups.normal,
      UserGroups.admin,
      UserGroups.supervisor,
    ],
  },
];

export const HeaderComplementaryApps = () => {
  const userSelected = useUserStore((store) => store.user);
  const companySelected = useCompanyStore((store) => store.company);

  const companyHasCheckIn = useMemo(() => {
    if (companySelected?.modules?.length > 0) {
      return companySelected.modules.some(
        (module) => module.name === "Check-in"
      );
    }
  }, [companySelected]);

  return (
    <NavigationMenu className="relative z-50">
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger hideArrow>
            <LayoutGrid className="h-6 w-6" />
          </NavigationMenuTrigger>

          <NavigationMenuContent className="absolute z-50">
            <ul className="p-0">
              {companyHasCheckIn ? (
                <li>
                  <NavigationMenuLink asChild>
                    <Link to="/check-in">
                      <div className="flex flex-col p-3 w-32 items-center justify-center hover:bg-stone-100 border">
                        <MapPinCheck className="h-6 w-6 text-gray-600" />
                        <span className="text-xs text-center text-gray-600 font-medium mt-2">
                          Check-in
                        </span>
                      </div>
                    </Link>
                  </NavigationMenuLink>
                </li>
              ) : null}

              {userSelected &&
                complementaryApps
                  .filter((app) =>
                    app.userGroupsAllowed.length > 0
                      ? app.userGroupsAllowed.includes(userSelected?.group)
                      : true
                  )
                  .map((app) => (
                    <li key={app.name}>
                      <NavigationMenuLink asChild>
                        <Link to={app.link}>
                          <div className="flex flex-col p-3 w-32 items-center justify-center hover:bg-stone-100 border">
                            <app.icon className="h-6 w-6 text-gray-600" />
                            <span className="text-xs text-center text-gray-600 font-medium mt-2">
                              {app.name}
                            </span>
                          </div>
                        </Link>
                      </NavigationMenuLink>
                    </li>
                  ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
};
