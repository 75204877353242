import { Skeleton } from "../ui";

export const CheckboxSkeleton = ({ length }: { length?: number }) => {
  return (
    <div className="flex flex-col gap-y-2 shadow-sm rounded-md ring-offset-background ring-gray-200 ring-1 px-3 py-2">
      {new Array(length || 5).fill(null).map((_, i) => (
        <div key={i} className="flex items-center gap-x-4">
          <Skeleton className="w-4 h-4 rounded-sm" />
          <Skeleton className="h-3 w-48" />
        </div>
      ))}
    </div>
  );
};
