import { SetStateAction, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Sheet,
  SheetContent,
  SheetFormFilterSkeleton,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  useToast,
} from "@/components";
import { CheckboxSkeleton } from "@/components/CheckboxSkeleton/CheckboxSkeleton";
import {
  useMutationEditUser,
  useMutationUserConnectOriginalId,
  useMutationUserDisconnectOriginalId,
  useQueryGetGroups,
  useQueryGetReportGroups,
  useQueryGetReports,
  useQueryGetUser,
  useQueryUserGetSalesPeople,
} from "@/service/administrativeArea/administrative-area";
import { useCompanyStore } from "@/store/company";
import { AdministrativeAreaActionsProps } from "@/types/administrativeArea";
import { CheckedState } from "@radix-ui/react-checkbox";
import { AdministrativeAreaSheetFooterSticky } from "../components/AdministrativeAreaSheetFooterSticky";
import { UserGroups } from "@/constants/user";
import { sellerProps } from "@/types/administrativeArea/user-link";

interface groupProps {
  id?: number;
  name?: string;
  permissions?: [object];
}

interface ReportObjectProps {
  reports: number[];
  reportsapi: number[];
  reportGroups: number[];
}

interface EditUserProps {
  first_name: string;
  last_name: string;
  email: string;
  groups: string;
}

export const AdministrativeAreaUsersActionsEdit: React.FC<
  AdministrativeAreaActionsProps
> = ({ row, children, isSheetOpen, setIsSheetOpen }) => {
  const [sheetOpen, setSheetOpen] = useState(isSheetOpen);
  const [seller, setSeller] = useState<sellerProps>();
  const [group, setGroup] = useState<groupProps>({});

  const [reportsObject, setReportsObject] = useState<ReportObjectProps>({
    reports: [],
    reportsapi: [],
    reportGroups: [],
  });

  const { data: dataGetUser, isFetching: isFetchingUser } = useQueryGetUser(
    row.original.id,
    {
      onSuccess: (data: {
        groups: SetStateAction<groupProps>[];
        reports: { id: number }[];
        reportsapi: { id: number }[];
        reports_group: { id: number }[];
      }) => {
        setGroup(data.groups[0]);
        setReportsObject(() => {
          return {
            reports: [
              ...data.reports.map((report: { id: number }) => report.id),
            ],
            reportsapi: [
              ...data.reportsapi.map(
                (reportapi: { id: number }) => reportapi.id
              ),
            ],
            reportGroups: [
              ...data.reports_group.map(
                (reportgroup: { id: number }) => reportgroup.id
              ),
            ],
          };
        });
      },
    }
  );

  const queryClient = useQueryClient();
  const { toast } = useToast();
  const { mutate } = useMutationEditUser({});

  const companySelected = useCompanyStore((store) => store.company);
  const { data: dataGroups } = useQueryGetGroups({});
  const { data: dataReports, isFetching: isFetchingReports } =
    useQueryGetReports(companySelected.id, {});
  const { data: dataReportGroups, isFetching: isFetchingReportGroups } =
    useQueryGetReportGroups(companySelected.id, {});

    const { data: dataSalesPeople, isFetching: isFetchingSalesPeople } =
    useQueryUserGetSalesPeople(companySelected.id, {});
    useEffect(() => 
    {
      if(!isFetchingSalesPeople && dataSalesPeople && dataSalesPeople.length > 0){
        setSeller(
          dataSalesPeople?.filter(
            (id) => id?.SKVENDEDOR == row.original?.user_sales?.salesman_id
          )[0]
        );
      }
    }, [dataSalesPeople, isFetchingSalesPeople, setSeller, row.original?.user_sales?.salesman_id])
  const handleChange = (selectedGroupName: string) => {
    const selectedGroup = dataGroups.find(
      (group: { name: string }) => group.name === selectedGroupName
    );

    if (selectedGroup) {
      return setGroup(selectedGroup);
    }
  };

  const handleSelectCheckbox = (
    checked: CheckedState,
    type: string,
    id: number
  ) => {
    if (checked) {
      if (type === "api") {
        setReportsObject((valorAtual) => {
          return {
            ...valorAtual,
            reportsapi: [...valorAtual.reportsapi, id] as never,
          };
        });
      }
      if (type === "pbi") {
        setReportsObject((valorAtual) => {
          return {
            ...valorAtual,
            reports: [...valorAtual.reports, id] as never,
          };
        });
      }
      if (type === "group") {
        setReportsObject((valorAtual) => {
          return {
            ...valorAtual,
            reportGroups: [...valorAtual.reportGroups, id] as never,
          };
        });
      }
    }
    if (!checked) {
      if (type === "api") {
        setReportsObject((valorAtual) => {
          const filtered = valorAtual.reportsapi.filter((reportId) => {
            return reportId !== id;
          });
          return {
            ...valorAtual,
            reportsapi: filtered,
          };
        });
      }
      if (type === "pbi") {
        setReportsObject((valorAtual) => {
          const filtered = valorAtual.reports.filter((reportId) => {
            return reportId !== id;
          });
          return {
            ...valorAtual,
            reports: filtered,
          };
        });
      }
      if (type === "group") {
        setReportsObject((valorAtual) => {
          const filtered = valorAtual.reportGroups.filter((reportGroupId) => {
            return reportGroupId !== id;
          });
          return {
            ...valorAtual,
            reportGroups: filtered,
          };
        });
      }
    }
  };

  const onSubmit: SubmitHandler<EditUserProps> = (data) => {
    const username = data.email;

    mutate(
      {
        userId: row.original.id,
        ...data,
        company: companySelected.id,
        group: group,
        reports: reportsObject.reports,
        reportsapi: reportsObject.reportsapi,
        reportGroups: reportsObject.reportGroups,
        username: username,
      },
      {
        onSuccess: () => {
          toast({
            title: "Usuário atualizado com sucesso!",
            variant: "default",
            description: `O usuário ${data.first_name} ${data.last_name} foi atualizado com sucesso.`,
          });
          queryClient.refetchQueries(["queryGetUsers"]);
          queryClient.removeQueries(["queryGetUser"])

          setSheetOpen(false);
          if (setIsSheetOpen) setIsSheetOpen(false);

          if (seller?.SKVENDEDOR != row.original.user_sales?.salesman_id) {
            mutateConnect({
              salesmanId: Number(seller?.SKVENDEDOR),
              salesmanName: seller?.VENDEDOR,
              userId: row.original.id,
            });
          }
        },

        onError: () => {
          toast({
            title: "Erro!",
            description: "Não foi possível atualizar os dados do usuário.",
            variant: "destructive",
          });
        },
      }
    );
  };

  const { mutate: mutateConnect } = useMutationUserConnectOriginalId({
    onSuccess: () => {
      toast({
        variant: "default",
        title: "Usuário conectado",
        description: "O usuário foi conectado ao id do banco original",
      });

      queryClient.refetchQueries(["queryGetUsers"]);
      setSeller(undefined);
      setSheetOpen(false);
    },

    onError: () => {
      toast({
        variant: "destructive",
        title: "Erro!",
        description:
          "Não foi possível conectar o usuário ao id do banco original.",
      });
    },
  });

  const { mutate: mutateDisconnect } = useMutationUserDisconnectOriginalId({
    onSuccess: () => {
      toast({
        variant: "default",
        title: "Usuário desconectado",
        description: "O usuário foi desconectado do id do banco original",
      });

      setSeller(undefined);
    },

    onError: () => {
      toast({
        variant: "destructive",
        title: "Erro!",
        description: "Não foi possível desconectar o usuário.",
      });
    },
  });

  const form = useForm<EditUserProps>({
    defaultValues: {
      first_name: `${row?.original?.first_name}`,
      last_name: `${row?.original?.last_name}`,
      email: `${row?.original?.email}`,
      groups: `${row?.original?.groups[0].name}`,
    },
  });
  const checkboxFilter = ["Supervisor", "Supervisor Geral", "Normal"];

  return (
    <Sheet
      open={sheetOpen}
      onOpenChange={() => {
        setSheetOpen(!sheetOpen);
        if (setIsSheetOpen) setIsSheetOpen(!sheetOpen);
        form.reset();
      }}
    >
      {children && <SheetTrigger>{children}</SheetTrigger>}

      <SheetContent className="overflow-y-scroll min-w-[30vw] w-[30vw]">
        {isFetchingUser &&
        isFetchingReports &&
        isFetchingReportGroups &&
        isFetchingSalesPeople ? (
          <div className="flex flex-col gap-y-4">
            <SheetFormFilterSkeleton />
            {row.original.groups[0].name !== UserGroups.admin && (
              <>
                <CheckboxSkeleton />
                {row.original.groups[0].name === UserGroups.normal && (
                  <CheckboxSkeleton />
                )}
              </>
            )}
          </div>
        ) : (
          <>
            <SheetHeader isSticky>
              <SheetTitle>Atualizar usuário</SheetTitle>
            </SheetHeader>
            <Form {...form}>
              <form
                className="p-1 overflow-y-auto h-[calc(100dvh-160px)] relative"
                onSubmit={form.handleSubmit(onSubmit)}
              >
                <FormField
                  name="first_name"
                  render={() => (
                    <FormItem>
                      <FormLabel>Primeiro nome</FormLabel>
                      <FormControl>
                        <Input
                          {...form.register("first_name", {
                            required: true,
                          })}
                          placeholder="Digite o primeiro nome"
                          autoCapitalize="words"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  name="last_name"
                  render={() => (
                    <FormItem className="pt-6">
                      <FormLabel>Último nome</FormLabel>
                      <FormControl>
                        <Input
                          {...form.register("last_name", {
                            required: true,
                          })}
                          placeholder="Digite o último nome"
                          autoCapitalize="words"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  name="email"
                  render={() => (
                    <FormItem className="pt-6">
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          type="email"
                          {...form.register("email", { required: true })}
                          placeholder="Digite o email"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />

                {dataSalesPeople ? (
                  <div className="pt-6 space-y-3">
                    <Label className="text-sm">Vincular vendedor</Label>
                    <Select
                      disabled={
                        seller?.SKVENDEDOR ==
                          row.original.user_sales?.salesman_id &&
                        seller?.SKVENDEDOR != null &&
                        row.original?.user_sales?.salesman_id != null
                      }
                      value={seller?.IDVENDEDOR || ""}
                      onValueChange={(value) => {
                        setSeller(
                          dataSalesPeople?.filter(
                            (id) => id.IDVENDEDOR == value
                          )[0]
                        );
                      }}
                    >
                      <div className="flex items-center gap-x-2">
                        <SelectTrigger className="h-11 w-full">
                          <SelectValue
                            placeholder="Vincular..."
                            className="text-gray-400"
                          />
                        </SelectTrigger>
                        {seller?.SKVENDEDOR ==
                          row.original.user_sales?.salesman_id &&
                        seller?.SKVENDEDOR != null &&
                        row.original?.user_sales?.salesman_id != null ? (
                          <Button
                            variant="destructive"
                            type="button"
                            className="w-fit h-11"
                            onClick={() => {
                              mutateDisconnect({ userIdFlow: row.original.id });
                              setSeller(undefined);
                              row.original.user_sales = null;
                            }}
                          >
                            Desconectar
                          </Button>
                        ) : null}
                      </div>
                      <SelectContent side="top">
                        {dataSalesPeople?.map((seller: sellerProps, index) => (
                          <SelectItem key={index} value={seller.IDVENDEDOR}>
                            {seller.VENDEDOR}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>

                    <div className="flex justify-between gap-4">
                      <Button disabled variant={"outline"} className="w-32">
                        {seller?.IDVENDEDOR ? seller?.IDVENDEDOR : "Id"}
                      </Button>

                      <Button disabled variant={"outline"} className="w-full">
                        {seller?.VENDEDOR ? seller?.VENDEDOR : "Vendedor"}
                      </Button>
                    </div>
                  </div>
                ) : null}

                <FormField
                  name="groups"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem className="pt-6">
                      <FormLabel>Grupo</FormLabel>
                      <FormControl>
                        <Select
                          value={field.value}
                          onValueChange={(value) => {
                            field.onChange(value);
                            handleChange(value);
                          }}
                          {...form.register("groups", {
                            required: true,
                          })}
                        >
                          <SelectTrigger className="h-11">
                            <SelectValue placeholder="Selecione o grupo" />
                          </SelectTrigger>
                          <SelectContent side="top">
                            {dataGroups?.map(
                              (groups: { id: number; name: string }) => (
                                <SelectItem
                                  key={groups.id}
                                  value={`${groups.name}`}
                                >
                                  {groups.name}
                                </SelectItem>
                              )
                            )}
                          </SelectContent>
                        </Select>
                      </FormControl>
                    </FormItem>
                  )}
                />
                {group.name === UserGroups.normal && (
                  <>
                    <div className="mt-6 ">
                      <Label>Relatórios que o usuário pode acessar</Label>
                      <div className="pb-2" />
                      <div className="shadow-sm rounded-md ring-offset-background ring-gray-200 ring-1 px-3 py-1">
                        {dataReports?.pbi?.map(
                          (item: { id: number; name: string }) => (
                            <div className="hover:underline" key={item.id}>
                              <Checkbox
                                id={"pbi" + item.id}
                                className="align-middle"
                                key={item.id}
                                value={item.id}
                                onCheckedChange={(checked) => {
                                  handleSelectCheckbox(checked, "pbi", item.id);
                                }}
                                defaultChecked={dataGetUser?.reports?.some(
                                  (report: { id: number }) => {
                                    return report.id === item.id;
                                  }
                                )}
                              />

                              <Label
                                key={"labelpbi" + item.id}
                                htmlFor={"pbi" + item.id}
                                className="ml-2 align-middle"
                              >
                                {item.name} (pbi)
                              </Label>
                            </div>
                          )
                        )}
                        {dataReports?.api?.map(
                          (item: { id: number; name: string }) => (
                            <div className="hover:underline" key={item.id}>
                              <Checkbox
                                id={"api" + item.id}
                                className="align-middle"
                                key={item.id}
                                value={item.id}
                                onCheckedChange={(checked) => {
                                  handleSelectCheckbox(checked, "api", item.id);
                                }}
                                defaultChecked={dataGetUser?.reportsapi?.some(
                                  (reportapi: { id: number }) => {
                                    return reportapi.id === item.id;
                                  }
                                )}
                              />

                              <Label
                                key={"labelapi" + item.id}
                                htmlFor={"api" + item.id}
                                className="ml-2 align-middle"
                              >
                                {item.name} (api)
                              </Label>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </>
                )}

                {checkboxFilter.includes(group.name!) && (
                  <>
                    <div className="mt-6 ">
                      <Label>Grupos que o usuário pode acessar</Label>
                      <div className="pb-2" />
                      <div className="shadow-sm rounded-md ring-offset-background ring-gray-200 ring-1 px-3 py-1">
                        {dataReportGroups?.map(
                          (item: { id: number; name: string }) => (
                            <div className="hover:underline" key={item.id}>
                              <Checkbox
                                id={"reportgroup" + item.id}
                                className="align-middle"
                                key={item.id}
                                value={item.id}
                                onCheckedChange={(checked) => {
                                  handleSelectCheckbox(
                                    checked,
                                    "group",
                                    item.id
                                  );
                                }}
                                defaultChecked={dataGetUser?.reports_group?.some(
                                  (reportgroup: { id: number }) => {
                                    return reportgroup.id === item.id;
                                  }
                                )}
                              />
                              <Label
                                key={"labelreportgroup" + item.id}
                                htmlFor={"reportgroup" + item.id}
                                className="ml-2 align-middle"
                              >
                                {item.name}
                              </Label>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </>
                )}
              </form>
            </Form>
            <AdministrativeAreaSheetFooterSticky
              buttonText={"Atualizar"}
              form={form}
              onSubmit={onSubmit}
            />
          </>
        )}
      </SheetContent>
    </Sheet>
  );
};
