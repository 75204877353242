import { useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import {  CardContent, } from "@/components";
import { DataTable } from "@/components/DataTable/DataTable";
import { DataTableSkeleton } from "@/components/DataTable/DataTableSkeleton";
import { useQueryGetUsers } from "@/service/administrativeArea/administrative-area";
import { useCompanyStore } from "@/store/company";
import { AdministrativeAreaUsersActions } from "./AdministrativeAreaUsersActions";
import { AdministrativeAreaUsersActionsEdit } from "./AdministrativeAreaUsersActionsEdit";
import { UsersProps } from "@/types/administrativeArea/user-link";

export const AdministrativeAreaUsersGeneral = () => {
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();

  const companySelected = useCompanyStore((store) => store.company);

  const columns: ColumnDef<UsersProps>[] = useMemo(
    () => [
      {
        accessorKey: "full_name",
        accessorFn: (row) => `${row.first_name} ${row.last_name}`,
        header: "Nome",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "role",
        accessorFn: (row) => `${row.groups[0].name}`,
        filterFn: (row, _, filterValue) => {
          return filterValue.includes(row.original.groups[0].name);
        },
        header: "Grupo",
      },
      {
        header: "Vendedor Vinculado",
        accessorFn: (row) =>
          `${row.user_sales != undefined ? row.user_sales.name : "Sem Vínculo"}`,
      },
      {
        accessorKey: "is_active",
        header: "Status",
        accessorFn: (row) => row.is_active,
        cell: ({ row }) => {
          return row.original.is_active ? "Ativo" : "Inativo";
        },
        filterFn: (row, _, filterValue) => {
          return filterValue.includes(row.original.is_active);
        },
      },
    ],
    []
  );
  const facetedFilterColumns = [
    {
      column: "role",
      title: "Grupo",
      options: [
        {
          label: "Admin",
          value: "Admin",
        },
        {
          label: "Supervisor Geral",
          value: "Supervisor Geral",
        },
        {
          label: "Supervisor",
          value: "Supervisor",
        },
        {
          label: "Normal",
          value: "Normal",
        },
      ],
    },
    {
      column: "is_active",
      title: "Status",
      options: [
        {
          label: "Ativo",
          value: true,
        },
        {
          label: "Inativo",
          value: false,
        },
      ],
    },
  ];

  const { data: dataUsers, isLoading } = useQueryGetUsers(
    companySelected.id,
    {}
  );

  if (isLoading) {
    return <DataTableSkeleton />;
  }

  return (
    <>
        
        <CardContent className="max-md:px-3 max-md:pb-2">
          <DataTable
            data={dataUsers}
            columns={columns}
            autoResetPageIndex={false}
            rowAction={<AdministrativeAreaUsersActions />}
            facetedFilterColumns={facetedFilterColumns}
            defaultFiltering={[{ value: [true], id: "is_active" }]}
            onSelectDataRow={(row) => {
              setSelectedRow(row);
              setIsSheetOpen(!isSheetOpen);
            }}
            isHeaderSticky
            height="h-[56vh] max-sm:h-[48vh]"
          />
        </CardContent>
   
      {isSheetOpen && (
        <AdministrativeAreaUsersActionsEdit
          row={selectedRow}
          isSheetOpen={isSheetOpen}
          setIsSheetOpen={setIsSheetOpen}
        />
      )}
    </>
  );
};
