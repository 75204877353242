import { useRef, useEffect, useCallback } from "react";

type CallbackFunction = (...args: any[]) => void;

export function useThrottle(fun: CallbackFunction, timeout: number) {
  const timer = useRef<NodeJS.Timeout | null>(null);

  const cancel = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }, [timer]);

  useEffect(() => {
    return () => cancel();
  }, [cancel]);

  return (...args: any[]) => {
    cancel();

    timer.current = setTimeout(() => {
      timer.current = null;

      if (fun && typeof fun === "function") {
        fun(...args);
      }
    }, timeout);
  };
}
