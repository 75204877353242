import { ReactElement } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

interface TanstackQueryProps {
  children: ReactElement;
}

export const TanstackQuery = ({ children }: TanstackQueryProps) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        staleTime: 0,
        cacheTime: 0,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
