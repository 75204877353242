import { Separator } from "@/components";
import React from "react";

interface HeaderAdministrativeProps {
  title: string;
  subtitle?: string | React.ReactElement;
  buttonAction?: React.ReactElement;
  variant?: "default" | "compact";
}

export const HeaderAdministrative = ({
  title,
  subtitle,
  buttonAction,
  variant = "default",
}: HeaderAdministrativeProps) => {
  if (variant === "compact") {
    return (
      <div className="flex justify-between items-center my-2">
        <div className="space-y-0.5">
          <h2 className="font-semibold tracking-tight text-gray-500">
            {title}
          </h2>

          {subtitle ? (
            <p className="text-muted-foreground text-sm max-md:hidden">
              {subtitle}
            </p>
          ) : null}
        </div>

        {buttonAction ? (
          <div className="grid items-center max-sm:w-fit">{buttonAction}</div>
        ) : null}
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="space-y-0.5">
          <h2 className="text-xl font-bold tracking-tight text-gray-600">
            {title}
          </h2>

          <div className="w-full">
            <p className="text-muted-foreground text-sm max-md:hidden">
              {subtitle}
            </p>
          </div>
        </div>

        {buttonAction && (
          <div className="grid items-center max-sm:w-fit">{buttonAction}</div>
        )}
      </div>

      <Separator className="mt-6 max-md:mt-4" />
    </div>
  );
};
