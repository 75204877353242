import { X } from "lucide-react";

import { DataTableToolbarProps } from "@/types/dataTable";
import { Button, Input } from "..";
import { DataTableFacetedFilter } from "./DataTableFacetedFilter";
import { useThrottle } from "@/hooks/useThrottle";

export function DataTableToolbar<TData>({
  table,
  facetedFilterColumns,
  setGlobalFilterValue,
  headerAction,
}: DataTableToolbarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0;

  const handleInputChange = useThrottle(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setGlobalFilterValue(String(event.target.value)),
    300
  );

  return (
    <div className="flex items-center justify-between max-md:flex-col gap-2 max-md:w-full">
      <div className="flex flex-1 items-center gap-2 max-md:flex-col max-md:w-full">
        <Input
          name="dataTableToolbarFilter"
          placeholder="Filtrar..."
          onChange={handleInputChange}
          className="h-10 w-96 max-md:w-full"
        />

        {facetedFilterColumns
          ? facetedFilterColumns?.map(
              (item) =>
                table.getColumn(item?.column) && (
                  <DataTableFacetedFilter
                    key={item?.column}
                    column={table.getColumn(item?.column)}
                    title={item.title}
                    options={item.options}
                  />
                )
            )
          : null}

        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3 animate-slide-in"
          >
            <p className="max-sm:hidden">Limpar</p>
            <X className="ml-2 h-4 w-4 max-sm:ml-0" />
          </Button>
        )}
      </div>
      {headerAction}
    </div>
  );
}
