import { X } from "lucide-react";
import React, { useState } from "react";
import { useQueryClient } from "react-query";

import {
  Button,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator,
  Skeleton,
  useToast,
} from "@/components";
import {
  useMutationUserAddFollower,
  useMutationUserDeleteFollower,
  useQueryUserGetFollowing,
  useQueryUserGetSalesPeople,
} from "@/service/administrativeArea/administrative-area";
import { useCompanyStore } from "@/store/company";
import { sellerProps } from "@/types/administrativeArea/user-link";

interface AdministrativeAreaUsersActionsLinkSellerFollowingProps {
  row?: any;
  setSheetOpen: any;
}

export const AdministrativeAreaUsersActionsLinkSellerFollowing: React.FC<
  AdministrativeAreaUsersActionsLinkSellerFollowingProps
> = ({ row, setSheetOpen }) => {
  const companySelected = useCompanyStore((store) => store.company);

  const [seller, setSeller] = useState<sellerProps>();

  const queryClient = useQueryClient();
  const { toast } = useToast();

  const { data: dataSalesPeople } = useQueryUserGetSalesPeople(
    companySelected.id,
    {}
  );

  const { data: dataFollowing, isLoading: isLoadingFollowing } =
    useQueryUserGetFollowing(row.original.id, {});

  const { mutate: mutateAddFollowing } = useMutationUserAddFollower({
    onSuccess: (data) => {
      if (!data.error) {
        toast({
          variant: "default",
          title: "Sucesso!",
          description: `Agora você está acompanhando o vendedor ${seller?.VENDEDOR}`,
        });
        queryClient.refetchQueries(["queryUserGetFollowing"]);
        setSeller(undefined);
      }

      if (
        data.error &&
        data.message.includes("não possui um status de vendedor")
      ) {
        toast({
          variant: "destructive",
          title: "Erro!",
          description: `Este usuário não possui status de vendedor`,
        });
      }
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: "Erro!",
        description: `Não foi possível seguir o vendedor ${seller?.VENDEDOR}`,
      });
    },
  });

  const { mutate: mutateDeleteFollowing } = useMutationUserDeleteFollower({
    onSuccess: () => {
      toast({
        variant: "default",
        title: "O vendedor não está mais sendo seguido!",
        description: `O vendedor parou de ser acompanhado`,
      });

      queryClient.refetchQueries(["queryUserGetFollowing"]);
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: "Erro!",
        description: `Não foi possível parar de seguir o vendedor`,
      });
    },
  });

  return (
    <div className="flex flex-col gap-y-3">
      {isLoadingFollowing && <Skeleton className="w-full h-11" />}

      {dataFollowing && row.original.user_sales && (
        <Label className="text-md">
          Usuário vinculado a um vendedor não pode acompanhar outros vendedores
        </Label>
      )}

      {dataFollowing && dataSalesPeople && !row.original.user_sales && (
        <>
          <Label className="text-md">Vendedor para acompanhar</Label>
          <Select
            value={seller?.IDVENDEDOR}
            onValueChange={(value) => {
              setSeller(
                dataSalesPeople.filter((id) => id.IDVENDEDOR == value)[0]
              );
            }}
          >
            <SelectTrigger className="h-11">
              <SelectValue placeholder="Acompanhar..." />
            </SelectTrigger>
            <SelectContent side="top">
              {dataSalesPeople?.map((seller: sellerProps) => (
                <SelectItem key={seller.IDVENDEDOR} value={seller.IDVENDEDOR}>
                  {seller.VENDEDOR}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <div className="flex justify-between">
            <Button
              variant="outline"
              onClick={() => {
                setSheetOpen(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              disabled={seller ? false : true}
              onClick={() =>
                mutateAddFollowing({
                  companyId: companySelected.id,
                  followerId: row.original.id,
                  userId: seller?.SKVENDEDOR,
                })
              }
            >
              Acompanhar
            </Button>
          </div>
        </>
      )}

      {isLoadingFollowing && (
        <div className="flex flex-col gap-y-2">
          <div className="flex justify-between items-center">
            <div className="flex gap-x-4">
              <Skeleton className="w-16 h-3" />
              <Skeleton className="w-48 h-3" />
            </div>
            <Skeleton className="h-5 w-5" />
          </div>
          <div className="flex justify-between items-center">
            <div className="flex gap-x-4">
              <Skeleton className="w-16 h-3" />
              <Skeleton className="w-48 h-3" />
            </div>
            <Skeleton className="h-5 w-5" />
          </div>
          <div className="flex justify-between items-center">
            <div className="flex gap-x-4">
              <Skeleton className="w-16 h-3" />
              <Skeleton className="w-48 h-3" />
            </div>
            <Skeleton className="h-5 w-5" />
          </div>
        </div>
      )}

      {!isLoadingFollowing && dataFollowing && dataFollowing?.length > 0 && (
        <div className="flex-col flex gap-4 pt-6">
          <Label>Seguindo:</Label>

          {dataFollowing?.map((follower, i) => {
            return (
              <div className="flex flex-col gap-y-2" key={"following_" + i}>
                <div className="flex justify-between items-center">
                  <Label>Nome: {follower.name}</Label>
                  <Button
                    variant={"ghost"}
                    onClick={() =>
                      mutateDeleteFollowing({ followerId: follower.id })
                    }
                  >
                    <X size={20} />
                  </Button>
                </div>
                <Separator className="w-full" />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
