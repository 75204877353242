import { useEffect, useState } from "react";

import { useToast } from "@/components";
import {
  PB_TOKEN,
  PB_TOKEN2,
  SESSION_COMPANY,
  SESSION_USER,
  USER_IP,
  USER_TOKEN,
} from "@/constants";
import { emptyCompany, useCompanyStore } from "@/store/company";
import { useUserStore } from "@/store/user";
import { usePostHog } from "posthog-js/react";
import { useNavigate } from "react-router-dom";

interface UserTokenProps {
  refresh: string;
  access: string;
}

const useAuthentication = () => {
  const setUser = useUserStore((store) => store.setUser);
  const setCompany = useCompanyStore((store) => store.setCompany);
  const { toast } = useToast();
  const navigate = useNavigate();
  const posthog = usePostHog();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const storedUser = await getUserInformation();

        if (storedUser) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        toast({
          variant: "destructive",
          title: "Erro ao verificar autenticação:",
          description: `: ${error}`,
        });
      } finally {
        setIsInitialized(true);
      }
    };

    checkAuthentication();
  }, [toast]);

  const logout = () => {
    posthog.reset();
    setUser(null);
    setCompany(emptyCompany);
    localStorage.removeItem(USER_IP);
    localStorage.removeItem(USER_TOKEN);
    localStorage.removeItem(SESSION_COMPANY);
    localStorage.removeItem(SESSION_USER);
    localStorage.removeItem(PB_TOKEN);
    localStorage.removeItem(PB_TOKEN2);
    setIsAuthenticated(false);
    navigate("/login");
  };

  const getUserInformation = async () => {
    return new Promise((resolve) => {
      const storedUser = localStorage.getItem(USER_TOKEN);
      resolve(storedUser ? JSON.parse(storedUser) : null);
    });
  };

  const setUserInlocalStorage = async (user: UserTokenProps) => {
    return new Promise<void>((resolve) => {
      localStorage.setItem(USER_TOKEN, JSON.stringify(user));
      setIsAuthenticated(true);
      resolve();
    });
  };

  return {
    isAuthenticated,
    isInitialized,
    setUserInlocalStorage,
    logout,
  };
};

export default useAuthentication;
