import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components";
import { cn } from "@/lib/utils";
import {
  ColumnDef,
  ColumnFiltersState,
  ExpandedState,
  Row,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer, VirtualItem } from "@tanstack/react-virtual";
import { ChevronDown, ChevronUp } from "lucide-react";
import * as React from "react";
import { DataTablePagination } from "./DataTablePagination";
import { DataTableRowActions } from "./DataTableRowActions";
import { DataTableToolbar } from "./DataTableToolbar";
import { AdministrativeAreaDataTableFilteredRowsContext } from "@/routes/AdministrativeArea/utils";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  rowAction?: React.ReactElement;
  canChangePageSize?: boolean;
  hasPagination?: boolean;
  showAllRows?: boolean;
  autoResetPageIndex?: boolean;
  rowCount?: number;
  subRowStore?: boolean;
  hasFilter?: boolean;
  isHeaderSticky?: boolean;
  isVirtualized?: boolean;
  virtualizedTableClassName?: string;
  height?: string;
  enableExpanding?: boolean;
  renderSubComponent?: any;
  onSelectDataRow?: any;
  isSubComponent?: boolean;
  emptyStateDescription?: string;
  externalGlobalFilterValue?: string;
  facetedFilterColumns?: {
    title: string;
    column: string;
    options: {
      label: string;
      value: string | boolean | number;
      icon?: React.ComponentType<{ className?: string }>;
    }[];
  }[];
  defaultSorting?: { desc: boolean; id: string }[];
  defaultFiltering?: { value: any[]; id: string }[];
  headerAction?: React.ReactElement;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  rowAction,
  canChangePageSize = true,
  hasPagination = true,
  autoResetPageIndex = true,
  showAllRows = false,
  hasFilter = true,
  isHeaderSticky = false,
  isVirtualized = false,
  virtualizedTableClassName,
  height,
  subRowStore,
  facetedFilterColumns,
  defaultSorting = [],
  defaultFiltering = [],
  renderSubComponent,
  onSelectDataRow,
  isSubComponent,
  emptyStateDescription,
  headerAction,
  externalGlobalFilterValue,
}: DataTableProps<TData, TValue>) {
  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilterValue, setGlobalFilterValue] = React.useState<string>("");
  const [sorting, setSorting] = React.useState<SortingState>(defaultSorting);
  const [columnFilters, setColumnFilters] =
    React.useState<ColumnFiltersState>(defaultFiltering);

  const { filteredRows, setFilteredRows } = React.useContext(
    AdministrativeAreaDataTableFilteredRowsContext
  );

  React.useEffect(() => {
    if (externalGlobalFilterValue) {
      setGlobalFilterValue(externalGlobalFilterValue);
      return;
    }

    setGlobalFilterValue("");
  }, [externalGlobalFilterValue]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
      columnFilters,
      globalFilter: globalFilterValue,
      expanded,
    },
    enableMultiSort: true,
    enableRowSelection: true,
    enableExpanding: !!renderSubComponent,
    getRowCanExpand: () => !!renderSubComponent,
    onExpandedChange: setExpanded,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilterValue,
    manualPagination: showAllRows,
    autoResetPageIndex: autoResetPageIndex,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    enableColumnFilters: true,
    enableFilters: true,
    enableSorting: true,
  });

  const handleSelectDataRow = (event, row) => {
    event.preventDefault();
    if (onSelectDataRow) {
      onSelectDataRow(row);
    }

    if (row.getCanExpand()) {
      row.toggleExpanded();
    }
  };

  const renderTable = (row: Row<TData>, virtualRow?: VirtualItem) => {
    return (
      <React.Fragment key={row?.id}>
        <TableRow
          className={cn(
            "h-12",
            row?.getIsExpanded() && "bg-brand-blue-100",
            (row?.getCanExpand() || onSelectDataRow) && "cursor-pointer",
            isSubComponent && "border-none h-9",
            isVirtualized && "absolute flex items-center w-full"
          )}
          data-state={row?.getIsSelected() && "selected"}
          onClick={(event) => handleSelectDataRow(event, row)}
          style={{
            transform: isVirtualized
              ? `translateY(${virtualRow?.start}px)`
              : undefined,
          }}
        >
          {row?.getVisibleCells().map((cell) => (
            <TableCell
              key={cell.id}
              className={cn(isVirtualized)}
              style={{
                width: isVirtualized ? `${cell.column.getSize()}px` : undefined,
              }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          ))}

          {rowAction || renderSubComponent ? (
            <TableCell
              key={"details"}
              className={cn(
                "px-3 justify-end flex items-center",
                !!renderSubComponent && "flex-grow"
              )}
            >
              {rowAction ? (
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <DataTableRowActions
                    isSubComponent={isSubComponent}
                    subRowStore={subRowStore}
                    rowAction={rowAction}
                    row={row}
                  />
                </div>
              ) : null}

              {renderSubComponent && row.getCanExpand() ? (
                <div className="px-3 justify-end flex h-11 items-center text-muted-foreground">
                  {row.getIsExpanded() ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </div>
              ) : null}
            </TableCell>
          ) : null}
        </TableRow>

        {row?.getIsExpanded() && renderSubComponent ? (
          <>
            <TableCell
              colSpan={columns.length + 1}
              key={`${row.id}-subcomponent`}
              className={cn(
                "w-full border-b pb-3",
                isVirtualized && "relative flex flex-col"
              )}
              style={{
                transform: isVirtualized
                  ? `translateY(${virtualRow?.end}px)`
                  : undefined,
              }}
            >
              {React.cloneElement(renderSubComponent, { row })}
            </TableCell>
          </>
        ) : null}
      </React.Fragment>
    );
  };

  const renderFooter = () => {
    const columnWithFooter = columns.some((column) => column.footer);

    if (columnWithFooter) {
      return (
        <TableFooter>
          {table.getFooterGroups().map((footerGroup) => (
            <TableRow key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <TableCell key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableFooter>
      );
    }

    return null;
  };

  const { rows } = table.getRowModel();

  React.useEffect(() => {
    if (rows.length !== filteredRows.length) {
      setFilteredRows(rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, setFilteredRows]);

  const tableContainerRef = React.useRef<HTMLTableElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 48,
    getScrollElement: () => tableContainerRef.current,
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 30,
  });

  return (
    <div className="space-y-4 h-full">
      {hasFilter && (
        <DataTableToolbar
          setGlobalFilterValue={setGlobalFilterValue}
          facetedFilterColumns={facetedFilterColumns}
          headerAction={headerAction}
          table={table}
        />
      )}

      <div
        className={cn(
          "rounded-md border overflow-hidden",
          isHeaderSticky &&
            !isVirtualized &&
            "relative h-[42vh] w-full rounded-md border",
          isHeaderSticky && !isVirtualized && rows.length > 7 && "h-[42vh]",
          isSubComponent && "relative h-full w-full border-none",
          height,
          isHeaderSticky && !isVirtualized && rows.length < 8 && "h-auto",
          renderSubComponent && height && height
        )}
      >
        <Table
          ref={isVirtualized ? tableContainerRef : null}
          className={cn(
            "w-full h-full",
            isHeaderSticky && !isVirtualized && "max-h-[42vh]",
            isHeaderSticky &&
              !isVirtualized &&
              rows.length > 7 &&
              "max-h-[42vh]",
            height,
            isHeaderSticky && !isVirtualized && rows.length < 8 && "h-auto",
            isHeaderSticky &&
              !isVirtualized &&
              rows.length < 3 &&
              "max-sm:h-auto",
            isVirtualized && "max-h-[60vh] min-h-36 overflow-y-auto grid",
            isVirtualized &&
              virtualizedTableClassName &&
              virtualizedTableClassName,
            isVirtualized && rows.length <= 8 && "flex-col flex"
          )}
        >
          <TableHeader
            className={cn(
              isHeaderSticky &&
                "sticky z-[5] top-0 bg-white border-separate border-b"
            )}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                key={headerGroup.id}
                className={cn(
                  isSubComponent && "border-none",
                  isVirtualized && "flex w-full"
                )}
              >
                {headerGroup.headers.map((header) => {
                  // @ts-expect-error - meta is not in the Column interface
                  const tooltipContent = header.column.columnDef.meta?.tooltip;

                  if (tooltipContent) {
                    return (
                      <TableHead
                        key={header.id}
                        colSpan={header.colSpan}
                        className={cn(
                          // @ts-expect-error - meta is not in the Column interface
                          header.column.columnDef.meta?.className,
                          isHeaderSticky &&
                            "after:absolute after:left-0 after:w-full before:absolute before:left-0 before:w-full before:top-[-1px] before:border-t after:bottom-[-1px] after:border-b",
                          isVirtualized && "flex items-center"
                        )}
                        style={{
                          width: isVirtualized
                            ? `${header.getSize()}px`
                            : undefined,
                        }}
                      >
                        <Tooltip>
                          <TooltipTrigger className="mx-[-3px]">
                            {header.isPlaceholder ? null : typeof header.column
                                .columnDef.header === "string" ? (
                              <div
                                className="flex hover:cursor-pointer items-center"
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                {header.column.columnDef.header}
                                {header.column.getIsSorted() === "asc" && (
                                  <ChevronUp className="ml-2" />
                                )}
                                {header.column.getIsSorted() === "desc" && (
                                  <ChevronDown className="ml-2" />
                                )}
                              </div>
                            ) : (
                              flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )
                            )}
                          </TooltipTrigger>
                          <TooltipContent>
                            <span>{tooltipContent}</span>
                          </TooltipContent>
                        </Tooltip>
                      </TableHead>
                    );
                  }

                  return (
                    <TableHead
                      key={header.id}
                      colSpan={header.colSpan}
                      className={cn(
                        // @ts-expect-error - meta is not in the Column interface
                        header.column.columnDef.meta?.className,
                        isHeaderSticky &&
                          "after:absolute after:left-0 after:w-full before:absolute before:left-0 before:w-full before:top-[-1px] before:border-t after:bottom-[-1px] after:border-b",
                        isVirtualized && "flex items-center"
                      )}
                      style={{
                        width: isVirtualized
                          ? `${header.getSize()}px`
                          : undefined,
                      }}
                    >
                      {header.isPlaceholder ? null : typeof header.column
                          .columnDef.header === "string" ? (
                        <div
                          className="flex hover:cursor-pointer items-center"
                          onClick={() => {
                            header.column.toggleSorting();
                          }}
                        >
                          {header.column.columnDef.header}
                          {header.column.getIsSorted() === "asc" && (
                            <ChevronUp className="ml-2" />
                          )}
                          {header.column.getIsSorted() === "desc" && (
                            <ChevronDown className="ml-2" />
                          )}
                        </div>
                      ) : (
                        flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )
                      )}
                    </TableHead>
                  );
                })}
                {rowAction || renderSubComponent ? (
                  <TableHead key={"details"} />
                ) : null}
              </TableRow>
            ))}
          </TableHeader>

          <TableBody
            className={cn(
              isVirtualized && "relative flex flex-col w-full justify-center"
            )}
          >
            {rows.length > 0 ? (
              isVirtualized ? (
                <div
                  style={{
                    height: `${rowVirtualizer.getTotalSize()}px`,
                  }}
                >
                  {rowVirtualizer
                    .getVirtualItems()
                    .map((virtualRow) =>
                      renderTable(rows[virtualRow.index], virtualRow)
                    )}
                </div>
              ) : (
                rows.map((row) => {
                  return renderTable(row);
                })
              )
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length + 1}
                  className={cn(
                    "h-24 text-center",
                    isVirtualized && "justify-center w-full flex items-center"
                  )}
                >
                  {emptyStateDescription || "Nenhum resultado."}
                </TableCell>
              </TableRow>
            )}
          </TableBody>

          {renderFooter()}
        </Table>
      </div>

      {hasPagination && !showAllRows ? (
        <DataTablePagination
          canChangePageSize={canChangePageSize}
          table={table}
        />
      ) : null}
    </div>
  );
}
