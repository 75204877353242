import { Button, Form, SheetFooter } from "@/components";

interface AdministrativeAreaSheetFooterStickyProps {
  form: any;
  onSubmit: any;
  buttonText: string;
  isButtonDisabled?: boolean;
  isButtonLoading?: boolean;
}

export const AdministrativeAreaSheetFooterSticky: React.FC<
  AdministrativeAreaSheetFooterStickyProps
> = ({
  form,
  onSubmit,
  buttonText,
  isButtonDisabled = false,
  isButtonLoading = false,
}) => {
  return (
    <SheetFooter isSticky>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Button
            variant={"primary"}
            type="submit"
            disabled={isButtonDisabled}
            isLoading={isButtonLoading}
          >
            {buttonText}
          </Button>
        </form>
      </Form>
    </SheetFooter>
  );
};
