import {
  DropdownMenuItem,
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components";
import React, { useState } from "react";
import { AdministrativeAreaActionsProps } from "@/types/administrativeArea";
import { AdministrativeAreaUsersActionsLinkSellerFollowing } from "./AdministrativeAreaUsersActionsLinkSellerFollowing";

export const AdministrativeAreaUsersActionsLinkSeller: React.FC<
  AdministrativeAreaActionsProps
> = ({ row }) => {
  const [sheetOpen, setSheetOpen] = useState(false);

  return (
    <Sheet
      open={sheetOpen}
      onOpenChange={() => {
        setSheetOpen(!sheetOpen);
      }}
    >
      <SheetTrigger>
        <DropdownMenuItem
          onSelect={(event) => {
            event.preventDefault();
          }}
        >
          Acompanhar vendedor
        </DropdownMenuItem>
      </SheetTrigger>

      <SheetContent className="pt-6">
        <SheetHeader className="pb-6">
          <SheetTitle>Acompanhar vendedor</SheetTitle>
          <SheetDescription>
            Selecione os vendedores que deseja acompanhar.
          </SheetDescription>
        </SheetHeader>

        <AdministrativeAreaUsersActionsLinkSellerFollowing
          row={row}
          setSheetOpen={setSheetOpen}
        />
      </SheetContent>
    </Sheet>
  );
};
