export const formatCurrency = (value: number, fractionDigits: number = 2) => {
  if (!value) return "R$ 0,00";

  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits: fractionDigits,
  }).format(value);
};

export const formatNumeric = (
  value: number,
  removeDecimals?: boolean
): string | number => {
  if (!value) return 0;

  const options: Intl.NumberFormatOptions = removeDecimals
    ? { maximumFractionDigits: 0, minimumFractionDigits: 0 }
    : {
        maximumFractionDigits: 2,
        minimumFractionDigits: parseInt(`${value}`) === value ? 0 : 2,
      };

  return new Intl.NumberFormat("pt-BR", options).format(value);
};


export const formatCNPJ = (value) => {
  const maskedvalue = value
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/, "$1.$2")
    .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    .replace(/\.(\d{3})(\d)/, ".$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");

  return maskedvalue;
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  const cleaned = phoneNumber.replace(/\D/g, "");

  const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return phoneNumber;
};
